import React from "react";
import "./VideoComponent.css";
const VideoComponent = () => {
  return (
    <div>
      <video
        loop
        muted
        autoPlay
        className="videoContainer"
        width="100%"
        height="100%"
      >
        <source src={require("../assets/Untitled.mp4")} type="video/mp4" />
        <div className=""></div>
      </video>
    </div>
  );
};

export default VideoComponent;
