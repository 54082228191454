import React from "react";
import NavBar from "../Components/NavBar";
import { TROUSER_DATA } from "../Data/ProductData";
import "./TrousersPage.css";
import FooterComponent from "../Components/FooterComponent";
const TrousersPage = () => {
  return (
    <div>
      <NavBar />
      <div className="row container">
        {TROUSER_DATA.map((i) => {
          return (
            <div  className="col-12  col-md-6 col-lg-3 col-xl-4 col  mt-2 ">
              <div className="card">
                <img
                  style={{
                    height: 600,
                    width: "100%",
                    objectFit: "contain",
                    marginTop: 10,
                  }}
                  src={i.Image}
                  className="card-img-top "
                />
                <div className="card-body">
                  <h2 className="card-title cardTitle">{i.Name}</h2>
                  <div className=" ">
                    <p className=" cardDesc ">{i.ShDescription}</p>
                  </div>
                  <div className="btnContainer">
                    <h4 className="btnTile">Show Details</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <FooterComponent />
    </div>
  );
};

export default TrousersPage;
