import React from "react";
import "./CategoryComponent.css";
import { Link } from "react-router-dom";
const CategoryComponent = () => {
  const catData = [
    {
      id: Math.random(),
      Name: "Tees/Tanktop",
      To: "/T-shirts",
      ImagePath: require("../assets/CategoryImages/850_8955.jpg"),
    },
    // {
    //   id: Math.random(),
    //   Name: "Trousers",
    //   To: "/Trousers",
    //   ImagePath: require("../assets/CategoryImages/Tr.jpg"),
    // },
    {
      id: Math.random(),
      Name: "Fleece",
      To: "/Hoodies",
      ImagePath: require("../assets/CategoryImages/_DSC4847.jpg"),
    },
    {
      id: Math.random(),
      Name: "PFD",
      To: "/Pfd",

      ImagePath: require("../assets/CategoryImages/_DSC4819.jpg"),
    },
  ];
  return (
    <div className=" py-3">
      <div>
        <h4 className="catHeading" style={{}}>
          CATEGORIES
        </h4>
      </div>
      <div class="container">
        <div
          style={{ justifyContent: "center", alignItems: "center" }}
          class="row"
        >
          {catData.map((i) => {
            return (
              <div
                // onClick={() => {
                //   alert(i.Name);
                // }}
                class="col-6 col-3 col-md-4 col-xl-3 col-sm-6  categoryTile"
              >
                <Link to={i.To}>
                  <img
                    className="catImage"
                    src={i.ImagePath}
                    style={{ height: "90%", width: "100%" }}
                  />
                </Link>

                <h4
                  className="canName"
                  style={{ textAlign: "center", marginTop: 10 }}
                >
                  {i.Name}
                </h4>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CategoryComponent;
