import React from "react";
import "./HomeBrandContainer.css";
const HomeBrandContainer = () => {
  return (
    <div className="container-fluid mainContainer">
      <div className="row">
        <div className="vImage col-12 col-xl-12 col-md-12 col-sm-12 co-xs-12">
          <img
            style={{ height: "100%", width: "100%" }}
            src={require("../assets/850_8936.jpg")}
          />
        </div>

        <div className="col col-6 col-xl-3 col-md-4 col-sm-6 co-xs-12 ">
          <img
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            src={require("../assets/HomeShocase/850_8958.jpg")}
          />
        </div>
        <div className=" col col-6 col-xl-3 col-md-4 col-sm-6 co-xs-12 ">
          <img
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            src={require("../assets/HomeShocase/_DSC4826.jpg")}
          />
        </div>
        <div className="col-6 col-xl-3 col-md-4 col-sm-6 co-xs-12 ">
          <img
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            src={require("../assets/HomeShocase/_DSC4842.jpg")}
          />
        </div>
        <div className=" col col-6 col-xl-3 col-md-4 col-sm-6 co-xs-12 ">
          <img
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            src={require("../assets/HomeShocase/_DSC4905.jpg")}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeBrandContainer;
