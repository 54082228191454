export const FEATURE_PRODUCTS = [
  {
    id: Math.random(),
    Name: "Product 1",
    ShDescription: "60% cotton and 40% Polyester",
    Image: require("../assets/FeatureProduct/_DSC4812.jpg"),
  },

  {
    id: Math.random(),
    Name: "Product 2",
    ShDescription: "60% cotton and 40% Polyester",
    Image: require("../assets/FeatureProduct/_DSC4900.jpg"),
  },
  {
    id: Math.random(),
    Name: "Product 3",
    ShDescription: "60% cotton and 40% Polyester",
    Image: require("../assets/FeatureProduct/_DSC4937.jpg"),
  },
  {
    id: Math.random(),
    Name: "Product 4",
    ShDescription: "60% cotton and 40% Polyester",
    Image: require("../assets/FeatureProduct/_DSC4996.jpg"),
  },
  {
    id: Math.random(),
    Name: "Product 5",
    ShDescription: "60% cotton and 40% Polyester",
    Image: require("../assets/FeatureProduct/850_8804.jpg"),
  },
  {
    id: Math.random(),
    Name: "Product 6",
    ShDescription: "60% cotton and 40% Polyester",
    Image: require("../assets/FeatureProduct/850_8874.jpg"),
  },
  {
    id: Math.random(),
    Name: "Product 7",
    ShDescription: "60% cotton and 40% Polyester",
    Image: require("../assets/FeatureProduct/850_9009.jpg"),
  },
];

export const TSHIRTCATDATA = [
  {
    id: Math.random(),
    Name: "Long Sleeves Tees",
    ShDescription: "100%cotton- 60%cotton/40%poly – 50%cotton-50%poly",
    Image: require("../assets/Tshirt/longsleevetees.jpg"),
    Style: "tubular/sideseam",
    Quality: "carded/combed",
    Composition: "100%cotton- 60%cotton/40%poly – 50%cotton-50%poly",
    Gsm: "120/145/160/180/200",
    YarnCoun: "18/1 – 20/1 – 26/1 – 30/1 32/1",
  },

  {
    id: Math.random(),
    Name: "Side Seam Tees",
    ShDescription: "100%cotton- 60%cotton/40%poly – 50%cotton-50%poly",
    Image: require("../assets/Tshirt/sideseamtees.jpg"),
    Style: "tubular/sideseam",
    Quality: "carded/combed",
    Composition: "100%cotton- 60%cotton/40%poly – 50%cotton-50%poly",
    Gsm: "120/145/160/180/200",
    YarnCoun: "18/1 – 20/1 – 26/1 – 30/1 32/1",
  },
  {
    id: Math.random(),
    Name: "Tank Top",
    ShDescription: "100%cotton- 60%cotton/40%poly – 50%cotton-50%poly",
    Image: require("../assets/Tshirt/tanktopmin.jpg"),
    Style: "tubular/sideseam",
    Quality: "carded/combed",
    Composition: "100%cotton- 60%cotton/40%poly – 50%cotton-50%poly",
    Gsm: "120/145/160/180/200",
    YarnCoun: "18/1 – 20/1 – 26/1 – 30/1 32/1",
  },
  {
    id: Math.random(),
    Name: "Tubular Tees",
    ShDescription: "100%cotton- 60%cotton/40%poly – 50%cotton-50%poly",
    Image: require("../assets/Tshirt/tubularteesmin.jpg"),
    Style: "tubular/sideseam",
    Quality: "carded/combed",
    Composition: "100%cotton- 60%cotton/40%poly – 50%cotton-50%poly",
    Gsm: "120/145/160/180/200",
    YarnCoun: "18/1 – 20/1 – 26/1 – 30/1 32/1",
  },
  {
    id: Math.random(),
    Name: "Women Tees",
    ShDescription: "100%cotton- 60%cotton/40%poly – 50%cotton-50%poly",
    Image: require("../assets/Tshirt/womenstees.jpg"),
    Style: "sideseam",
    Quality: "carded/combed",
    Composition: "100%cotton- 60%cotton/40%poly – 50%cotton-50%poly",
    Gsm: "120/145/160/180/200",
    YarnCoun: "18/1 – 20/1 – 26/1 – 30/1 32/1",
  },
];

export const TROUSER_DATA = [
  {
    id: Math.random(),
    Name: "2 Fleece Bottom",
    ShDescription: "60% cotton and 40% Polyester",
    Image: require("../assets/TrouserData/2fleecebottam.jpg"),
  },
  {
    id: Math.random(),
    Name: "3 Fleece Bottom",
    ShDescription: "60% cotton and 40% Polyester",
    Image: require("../assets/TrouserData/3fleecebottom.jpg"),
  },
];

export const HOODIE_DATA = [
  {
    id: Math.random(),
    Name: "Collar Zipper",
    ShDescription:
      "80%cotton-20%poly – 70%cotton-30%poly-60%cotton-40%poly – 50%cotton-50%poly",
    Image: require("../assets/HoodieData/collarzippermin.jpg"),
    Style: "Plain",
    Quality: "French terry /2end fleece / 3end fleece",
    Composition:
      "80%cotton-20%poly – 70%cotton-30%poly-60%cotton-40%poly – 50%cotton-50%poly",
    Gsm: "220/240/260/280/300/320/340",
    YarnCoun: "-",
  },
  {
    id: Math.random(),
    Name: "Hooded Crop",
    ShDescription:
      "80%cotton-20%poly – 70%cotton-30%poly-60%cotton-40%poly – 50%cotton-50%poly",
    Image: require("../assets/HoodieData/crophoodmin.jpg"),
    Style: "Plain",
    Quality: "French terry /2end fleece / 3end fleece",
    Composition:
      "80%cotton-20%poly – 70%cotton-30%poly-60%cotton-40%poly – 50%cotton-50%poly",
    Gsm: "220/240/260/280/300/320/340",
    YarnCoun: "-",
  },
  {
    id: Math.random(),
    Name: "Hooded Zipper",
    ShDescription: "60% cotton and 40% Polyester",
    Image: require("../assets/HoodieData/hoodedzippermin.jpg"),
    Style: "Plain",
    Quality: "French terry /2end fleece / 3end fleece",
    Composition:
      "80%cotton-20%poly – 70%cotton-30%poly-60%cotton-40%poly – 50%cotton-50%poly",
    Gsm: "220/240/260/280/300/320/340",
    YarnCoun: "-",
  },
  {
    id: Math.random(),
    Name: "Pull Over",
    ShDescription: "60% cotton and 40% Polyester",
    Image: require("../assets/HoodieData/pullovermin.jpg"),
    Style: "Plain",
    Quality: "French terry /2end fleece / 3end fleece",
    Composition:
      "80%cotton-20%poly – 70%cotton-30%poly-60%cotton-40%poly – 50%cotton-50%poly",
    Gsm: "220/240/260/280/300/320/340",
    YarnCoun: "-",
  },
  {
    id: Math.random(),
    Name: "Sweat Shirt",
    ShDescription:
      "80%cotton-20%poly – 70%cotton-30%poly-60%cotton-40%poly – 50%cotton-50%poly",
    Image: require("../assets/HoodieData/sweatshirtmin.jpg"),
    Style: "Plain",
    Quality: "French terry /2end fleece / 3end fleece",
    Composition:
      "80%cotton-20%poly – 70%cotton-30%poly-60%cotton-40%poly – 50%cotton-50%poly",
    Gsm: "220/240/260/280/300/320/340",
    YarnCoun: "-",
  },

  // {
  //   id: Math.random(),
  //   Name: "2 Fleece Bottom",
  //   ShDescription:
  //     "80%cotton-20%poly – 70%cotton-30%poly-60%cotton-40%poly – 50%cotton-50%poly",
  //   Image: require("../assets/TrouserData/2fleecebottam.jpg"),
  //   Style: "Plain",
  //   Quality: "French terry /2end fleece / 3end fleece",
  //   Composition:
  //     "80%cotton-20%poly – 70%cotton-30%poly-60%cotton-40%poly – 50%cotton-50%poly",
  //   Gsm: "220/240/260/280/300/320/340",
  //   YarnCoun: "-",
  // },
  {
    id: Math.random(),
    Name: "Trousers",
    ShDescription:
      "80%cotton-20%poly – 70%cotton-30%poly-60%cotton-40%poly – 50%cotton-50%poly",
    Image: require("../assets/TrouserData/3fleecebottom.jpg"),
    Style: "Plain",
    Quality: "French terry /2end fleece / 3end fleece",
    Composition:
      "80%cotton-20%poly – 70%cotton-30%poly-60%cotton-40%poly – 50%cotton-50%poly",
    Gsm: "220/240/260/280/300/320/340",
    YarnCoun: "-",
  },
];

export const PFD_DATA = [
  {
    id: Math.random(),
    Name: "Pfd Shorts",
    ShDescription: "80%cotton-20%poly",
    Image: require("../assets/pfdData/pfdshortsmin.jpg"),
    Style: "-",
    Quality: "French terry /2end fleece / 3end fleece",
    Composition: "80%cotton-20%poly",
    Gsm: "220/240/260/280/300/320/340",
    YarnCoun: "-",
  },

  {
    id: Math.random(),
    Name: "Pfd Pull Over",
    ShDescription: "80%cotton-20%poly",
    Image: require("../assets/pfdData/pfdpullovermin.jpg"),
    Style: "-",
    Quality: "French terry /2end fleece / 3end fleece",
    Composition: "80%cotton-20%poly",
    Gsm: "220/240/260/280/300/320/340",
    YarnCoun: "-",
  },
];
