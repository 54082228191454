import React from "react";
import "./FooterComponent.css";
import * as AiIcon from "react-icons/ai";
import { Link } from "react-router-dom";
const FooterComponent = () => {
  return (
    <footer class="bg-dark text-center text-white p-5 mt-3">
      <div className="row">
        <div className="col col-12 col-md-6 col-lg-4 my-3">
          <h4 className="footerHeading">ACCOUNTS</h4>
          <a
            style={{ textDecoration: "none" }}
            target="_blank"
            href="https://www.facebook.com/profile.php?id=100088172173924&mibextid=ZbWKwL"
          >
            <p className="footerPara">Facebook</p>
          </a>
          <a
            href="https://www.instagram.com/invites/contact/?i=z0wst6qwolno&utm_content=q0y0ds1"
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            <p className="footerPara">Instagram</p>
          </a>
        </div>
        <div className="col col-12 col-md-6 col-lg-4 my-3">
          <h4 className="footerHeading">COMPANY</h4>
          <Link to={"/AboutUs"} style={{ textDecoration: "none" }}>
            <p className="footerPara">About Us</p>
          </Link>
        </div>
        <div className="col col-12 col-md-6 col-lg-4 my-3">
          <h4 className="footerHeading">Contact</h4>
          <p className="footerPara">rauf@sidapparels.com </p>

          <h4 className="footerHeading">Address</h4>
          <p className="footerPara">
            E-38/A,CENTRAL AVENUE S.I.T.E. Karachi-Pakistan Karachi..
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
