import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./InfoModal.css";
const InfoModal = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-90w"
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Product Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{}}>
          <div className="row">
            <div className="col  imgConntainer">
              <img
                style={{ height: "100%", width: "100%" }}
                src={props.image}
              />
            </div>
            <div className="col">
              <h4 className="titleHeadig">Style</h4>
              <p className="paragraph">{props.style}</p>
              <h4 className="titleHeadig">Quality</h4>
              <p className="paragraph">{props.Quality}</p>
              <h4 className="titleHeadig">Composition</h4>
              <p className="paragraph">{props.Composition}</p>
              <h4 className="titleHeadig">Gsm</h4>
              <p className="paragraph">{props.Gsm}</p>
              <h4 className="titleHeadig">Yarn count</h4>
              <p className="paragraph">{props.Yarncount}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InfoModal;
