import logo from "./logo.svg";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";

import TShirtPage from "./Pages/TShirtPage";
import TrousersPage from "./Pages/TrousersPage";
import HoodiesPage from "./Pages/HoodiesPage";
import PfdPage from "./Pages/PfdPage";
import AboutUs from "./Pages/AboutUsPage";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/T-shirts" element={<TShirtPage />} />
        <Route path="/Trousers" element={<TrousersPage />} />
        <Route path="/Hoodies" element={<HoodiesPage />} />
        <Route path="/Pfd" element={<PfdPage />} />
        <Route path="/AboutUs" element={<AboutUs />} />
      </Routes>
    </Router>
  );
}

export default App;
