import React from "react";
import NavBar from "../Components/NavBar";
import "./AboutUsPage.css";
import FooterComponent from "../Components/FooterComponent";

const AboutUsPage = () => {
  return (
    <div>
      <NavBar />
      <div className="row container mt-5">
        <div className="col-12  col-sm-6 ">
          <img
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            src={require("../assets/2.jpg")}
          />
        </div>
        <div className="col-12 col-sm-6 colAboutUs">
          <h4 className="colHeading">About Us</h4>
          <p className="colPara">
            We are start-up in the market with tons of experience on the back
            and passionate young blood leadership on the front. We understand
            the hurdles of the current market position and we believe there is
            always a place for newcomers in the market in every situation.
            Newcomers always bring some new ideas which prove to be trendsetters
            in the market.
          </p>
          <div>
            <h4 className="colHeading">Our Goals</h4>
            <p className="colPara">
              * Satisfy our customer with best product on time with most
              competitive price.
            </p>
            <p className="colPara">
              * To take care of our employees in best possible way.
            </p>
            <p className="colPara">
              * Willing to become STRONGER rather BIGGER
            </p>
          </div>

          <div>
            <h4 className="colHeading">Manufacturing Facility</h4>
            <p className="colPara">
              We Have stitching depart facility in the house while we outsource
              knitting and dyeing from best knitter and dyeing houses of
              Karachi..
            </p>
          </div>

          <div>
            <h4 className="colHeading">Contact Us:</h4>
            <p className="colPara">
              E-38/A,CENTRAL AVENUE S.I.T.E. Karachi-Pakistan Karachi..
            </p>

            <p className="colPara">rauf@sidapparels.com</p>
          </div>
        </div>
      </div>
      <div>
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src={require("../assets/SiteImages/IMG_2912.JPG")}
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item">
              <img
                src={require("../assets/SiteImages/IMG_2916.JPG")}
                class="d-block w-100"
                alt="..."
              />
            </div>
          </div>

          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <FooterComponent />
    </div>
  );
};

export default AboutUsPage;
