import React from "react";
import NavBar from "../Components/NavBar";
import "./HomePage.css";
import VideoComponent from "../Components/VideoComponent";
import CategoryComponent from "../Components/CategoryComponent";
import FeatureProduct from "../Components/FeatureProduct";
import HomeBrandContainer from "../Components/HomeBrandContainer";
import FooterComponent from "../Components/FooterComponent";
const HomePage = () => {
  return (
    <div>
      <NavBar />
      <div>
        <VideoComponent />
      </div>
      <div>
        <h4 className="tagLine">#YOUORDERWEMAKE</h4>
      </div>
      <div className="my-5">
        <CategoryComponent />
      </div>
      {/* <div>
        <FeatureProduct />
      </div> */}
      <div>
        <HomeBrandContainer />
      </div>
      <div>
        <FooterComponent />
      </div>
    </div>
  );
};

export default HomePage;
