import React, { useState } from "react";
import NavBar from "../Components/NavBar";
import { TSHIRTCATDATA } from "../Data/ProductData";
import "./TShirtPage.css";
import FooterComponent from "../Components/FooterComponent";
import InfoModal from "../Components/InfoModal";

const TShirtPage = () => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedData, setSelectedData] = useState();
  return (
    <div>
      <NavBar />
      <div className="row container">
        {TSHIRTCATDATA.map((i) => {
          return (
            <div
              onClick={() => {
                setSelectedData(i);
                setModalShow(true);
              }}
              className="col-12  col-md-6 col-lg-4 col-xl-4   mt-2 "
            >
              <div className="card">
                <img
                  style={{
                    height: 600,
                    width: "100%",
                    objectFit: "contain",
                    marginTop: 10,
                  }}
                  src={i.Image}
                  className="card-img-top "
                />
                <div className="card-body">
                  <h2 className="card-title cardTitle">{i.Name}</h2>
                  <div className=" ">
                    <p className=" cardDesc ">{i.ShDescription}</p>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedData(i);
                      setModalShow(true);
                    }}
                    className="btnContainer"
                  >
                    <h4 className="btnTile">Show Details</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <InfoModal
        image={selectedData?.Image}
        show={modalShow}
        onHide={() => setModalShow(false)}
        style={selectedData?.Style}
        Quality={selectedData?.Quality}
        Composition={selectedData?.Composition}
        Gsm={selectedData?.Gsm}
        Yarncount={selectedData?.YarnCoun}
      />
      <FooterComponent />
    </div>
  );
};

export default TShirtPage;
