import React from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import * as AiIcon from "react-icons/ai";
const NavBar = () => {
  return (
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark navContainer">
      <div class="container-fluid">
        <nav class="navbar ">
          <a class="navbar-brand" href="/">
            <img
              style={{ borderRadius: 50 }}
              src={require("../assets/NavLogo.png")}
              alt=""
              width="50"
              height="50"
              class="d-inline-block align-text-top"
            />
          </a>
          <h4 className="brandName">Siddique Apparels</h4>
        </nav>

        {/* <a class="navbar-brand" href="#">
          Navbar
        </a> */}
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 mx-auto my-3">
            <li class="nav-item mx-2">
              <NavLink to={"/"} style={{ textDecoration: "none" }}>
                <p className="navTitle">Home</p>
              </NavLink>
            </li>

            <li class="nav-item mx-2">
              <NavLink to={"/T-shirts"} style={{ textDecoration: "none" }}>
                <p className="navTitle">Tees/Tanktop</p>
              </NavLink>
            </li>

            {/* <li class="nav-item mx-2 ">
              <NavLink to={"/Trousers"} style={{ textDecoration: "none" }}>
                <p className="navTitle">Trousers</p>
              </NavLink>
            </li> */}

            <li class="nav-item mx-2 ">
              <NavLink to={"/Hoodies"} style={{ textDecoration: "none" }}>
                <p className="navTitle">Fleece</p>
              </NavLink>
            </li>
            <li class="nav-item mx-2 ">
              <NavLink to={"/Pfd"} style={{ textDecoration: "none" }}>
                <p className="navTitle">PFD</p>
              </NavLink>
            </li>
          </ul>
          <div class="d-flex mx-2 ">
            <div className="">
              <NavLink to={"/AboutUs"}>
                <AiIcon.AiFillPhone style={{ color: "white", fontSize: 25 }} />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
